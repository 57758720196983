import React from "react";

export const Instagram = () => (
    <svg version="1.1" id="Flat" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
        viewBox="0 0 256 256">
        <path fill="#E5E5E5" class="st0" d="M160,128c0,17.7-14.3,32-32,32s-32-14.3-32-32s14.3-32,32-32C145.7,96,160,110.3,160,128z M228,84v88
        c0,30.9-25.1,56-56,56H84c-30.9,0-56-25.1-56-56V84c0-30.9,25.1-56,56-56h88C202.9,28,228,53.1,228,84z M176,128
        c0-26.5-21.5-48-48-48s-48,21.5-48,48s21.5,48,48,48C154.5,176,176,154.5,176,128z M192,76c0-6.6-5.4-12-12-12s-12,5.4-12,12
        s5.4,12,12,12S192,82.6,192,76z"/>
    </svg>
);
