import React from "react";

export const Twitter = () => (
  <svg
    width="18"
    height="15"
    viewBox="0 0 18 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.0152 3.67175C16.0265 3.82396 16.0265 3.9753 16.0265 4.12664C16.0265 8.75809 12.5139 14.0949 6.09448 14.0949C4.11674 14.0949 2.27941 13.52 0.733276 12.5215C1.01408 12.5537 1.28448 12.565 1.57654 12.565C3.1478 12.5688 4.67457 12.0415 5.91074 11.0682C5.18224 11.0549 4.47604 10.8137 3.89075 10.3782C3.30546 9.94271 2.87029 9.33465 2.64601 8.63894C2.86181 8.67112 3.07848 8.69286 3.30554 8.69286C3.61841 8.69286 3.93301 8.64937 4.22508 8.5737C3.43447 8.41351 2.72355 7.98344 2.21321 7.35664C1.70287 6.72984 1.42461 5.94499 1.42574 5.13555V5.09206C1.89114 5.35212 2.43108 5.51477 3.00308 5.53651C2.52389 5.21695 2.13097 4.78332 1.85937 4.27431C1.58777 3.7653 1.44595 3.19674 1.44654 2.61934C1.44654 1.96876 1.61901 1.37211 1.92148 0.851998C2.79866 1.93486 3.89271 2.82075 5.13277 3.45227C6.37282 4.0838 7.73123 4.44689 9.12001 4.51802C9.06628 4.2571 9.03334 3.9866 9.03334 3.71524C9.03312 3.2551 9.12325 2.79943 9.29861 2.37428C9.47396 1.94913 9.7311 1.56283 10.0553 1.23746C10.3795 0.912097 10.7644 0.654046 11.1881 0.478066C11.6117 0.302085 12.0658 0.211625 12.5243 0.211853C13.5296 0.211853 14.437 0.634555 15.0749 1.31818C15.8564 1.16651 16.6059 0.880196 17.2901 0.471913C17.0296 1.2815 16.4839 1.96799 15.7552 2.40278C16.4484 2.32344 17.1258 2.1405 17.765 1.86005C17.2876 2.55855 16.696 3.17107 16.0152 3.67175Z"
      fill="#DBDBDB"
    />
  </svg>
);
